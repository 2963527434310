<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ userInfo.email }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="link in links"
          :key="link.title"
          :to="link.route"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ link.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app flat clipped-left>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>SPMB UNIKARTA</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <v-btn text v-if="isLg">
        <span class="mr-2">{{ userInfo.first_name }}</span>
        <v-icon>mdi-user</v-icon>
      </v-btn>
      <v-btn text @click="logout" v-if="isLg">
        <span class="mr-2">Logout</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-menu bottom left v-if="isXs">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-icon class="justify-center">
              <v-icon>mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span class="mr-2">{{
                userInfo.first_name
              }}</span></v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon class="justify-center">
              <v-icon>mdi-lock</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer app>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>SPMB UNIKARTA</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "User",
  data() {
    return {
      isXs: false,
      isLg: false,
      drawer: true,
      links: [
        {
          title: "Dashboard User",
          icon: "fas fa-tachometer-alt",
          route: "/user"
        },
        {
          title: "Daftar Ulang",
          icon: "fas fa-user-alt",
          route: "/registrasiUlang"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("auth", ["userInfo"])
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
      this.isLg = window.innerWidth > 850;
    }
  },
  created() {
    this.$vuetify.theme.light = true;
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  }
};
</script>
